import Footer from "../../components/Footer/Footer";
import "./home.css";
import SettingsSuggestOutlinedIcon from "@mui/icons-material/SettingsSuggestOutlined";
import "./profile.css";
import "./addmn.css";

import avt from "../../components/male.jpg";
import { useEffect, useState } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import swal from "sweetalert";
function ResetPassword() {
	const [profile, setProfile] = useState(null);
	const [show, setShow] = useState(false);
	axios.interceptors.request.use(
		(config) => {
			const token = localStorage.getItem("user");

			if (token) {
				config.headers["Authorization"] = `Bearer ${token}`;
			}

			return config;
		},

		(error) => {
			return Promise.reject(error);
		}
	);
	const {
		register,
		handleSubmit,
		setError,
		formState: { errors },
	} = useForm();
	const [bank, setBank] = useState(null);
	const navigate = useNavigate();

	const onSubmit = (data) => {
		const formData = {
			password: data.oldpassword,
			newpassword: data.password,
		};
		axios
			.post(`https://server.vpmilks.com/auth/resetpassword`, formData)
			.then((res) => {
				swal("Sửa mật khẩu thành công");
				navigate("/mine");
			})
			.catch((err) => swal("Sai tên đăng nhập hoặc mật khẩu"));
	};
	return (
		<>
			<div className="app1">
				<div className="header_profile">
					<div className="info_profile">
						<div></div>
						<div className="avatar_profile">
							<div className="name_profile"></div>

							<div
								style={{
									marginLeft: "20px",
									fontSize: "1.2rem",
									fontWeight: 600,
									color: "white",
								}}
							>
								Đổi mật khẩu
							</div>
						</div>
						<Link to="/login" className="icon_setting_header">
							&times;
						</Link>
					</div>
				</div>
				<div className="content_profile">
					<form
						style={{ display: "block", padding: "15px" }}
						onSubmit={handleSubmit(onSubmit)}
					>
						<div>
							<div>
								<input
									className="ipadd"
									type="password"
									{...register("oldpassword", { required: true })}
									placeholder="Mật khẩu cũ"
								/>
							</div>
							<div>
								{" "}
								<input
									className="ipadd"
									type="password"
									{...register("password", { required: true })}
									placeholder="Mật khẩu mới"
								/>
							</div>
							<div>
								{" "}
								<input
									className="ipadd"
									type="password"
									{...register("checkpassword", { required: true })}
									placeholder="Nhập lại mật khẩu"
								/>
							</div>
							<button type="submit" className="btn-submit">
								Xác nhận
							</button>
						</div>
					</form>
				</div>

				<Footer profile={profile} />
			</div>
		</>
	);
}
export default ResetPassword;
