import { DashboardLayout } from "../../components/dashboard-layout";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "../../theme";
import "./request.css";

import {
	Box,
	Button,
	Container,
	Table,
	Pagination,
	tbody,
	TableCell,
	TableHead,
	tr,
	TextField,
} from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
function HistoryBetAll() {
	const [data, setData] = useState(null);
	const [newData, setNewData] = useState(null);
	const [status, setStatus] = useState(null);
	const [bet, setBet] = useState(null);
	const [searched, setSearched] = useState("");
	function formatDate(m) {
		new Date(m);
		const dateString =
			m.getUTCFullYear() +
			"/" +
			("0" + (m.getMonth() + 1)).slice(-2) +
			"/" +
			("0" + m.getDate()).slice(-2) +
			"  " +
			("0" + m.getHours()).slice(-2) +
			":" +
			("0" + m.getMinutes()).slice(-2);
		return dateString;
	}
	axios.interceptors.request.use(
		(config) => {
			const token = localStorage.getItem("user");

			if (token) {
				config.headers["Authorization"] = `Bearer ${token}`;
			}

			return config;
		},

		(error) => {
			return Promise.reject(error);
		}
	);
	const requestSearch = (searchedVal) => {
		setSearched(searchedVal);

		if (searchedVal !== "") {
			const filteredRows = JSON.parse(localStorage.getItem("data")).filter(
				(row) => {
					const a = row?.user?.username
						?.toString()
						.toLowerCase()
						.includes(searchedVal?.toString().toLowerCase());
					const b = (Number(row?.user?.iduser) + 2000)
						?.toString()
						.includes(searchedVal?.toString().toLowerCase());
					const c = row?.id_bet?.id_bet
						?.toString()
						.includes(searchedVal?.toString().toLowerCase());
					return a || b || c;
				}
			);
			setData(filteredRows);
		} else {
			setData(JSON.parse(localStorage.getItem("data")));
		}
	};
	useEffect(() => {
		axios
			.get(`https://server.vpmilks.com/history/all`, {})
			.then((res) => {
				localStorage.setItem("data", JSON.stringify(res.data.data));
				setData(res.data.data);
				setNewData(res.data.data);
			})
			.catch((res) => setData(null));
	}, []);
	const [pages, setPage] = useState(1);

	const handleChange = (e, page) => {
		setPage(page);
	};

	const submitSearch = (e) => {
		let search = newData;
		if (bet) {
			bet === "th" &&
				(search = search.filter(
					(item) => Number(item?.moneytai).toFixed(0) > 0
				));
			bet === "vina" &&
				(search = search.filter(
					(item) => Number(item?.moneyxiu).toFixed(0) > 0
				));
		}
		if (status) {
			search = search.filter((item) => item?.status_bet == status);
		}
		console.log(search);
		setData(search);
	};
	return (
		<>
			<ThemeProvider theme={theme}>
				<DashboardLayout>
					{
						<Container maxWidth={false}>
							<div className="container_set">Lịch sử giao dịch</div>
							<div className="form_set">
								<Box sx={{ minWidth: 1200 }} style={{ width: "100%" }}>
									<div className="filter-box">
										<input
											value={searched}
											className="form-control"
											type="text"
											onChange={(searchVal) =>
												requestSearch(searchVal.target.value)
											}
											placeholder="Tìm kiếm"
										/>
										<select onChange={(e) => setStatus(e.target.value)}>
											<option>Trạng thái</option>
											<option value="Win">Thắng</option>
											<option value="Lose">Thua</option>
											<option value="Pending">Chờ xử lý</option>
										</select>
										<select onChange={(e) => setBet(e.target.value)}>
											<option>Đặt hàng</option>
											<option value="th">TH TRUEMILK</option>
											<option value="vina">VINAMILK</option>
										</select>
										<button
											className="btn-submit btn-admin-1"
											style={{
												margin: "0",
												fontSize: "13px",
												lineHeight: "1",
												height: "33px",
											}}
											onClick={(e) => submitSearch(e)}
										>
											Tìm kiếm
										</button>
									</div>
									<table className="table table-striped table-hover table-sm align-middle">
										<thead>
											<tr>
												<th>ID</th>
												<th className="text-left">Nguời chơi</th>
												<th className="text-left">Kỳ</th>
												<th className="text-left">Đặt hàng</th>
												<th className="text-left">Điểm cược</th>
												<th className="text-left">Điểm thắng</th>
												<th className="text-left">Thời gian đặt</th>
												<th className="text-left">Trạng thái</th>
												<th className="text-left">Thời gian xử lý</th>
											</tr>
										</thead>
										{data != null ? (
											<tbody>
												{data
													?.slice((pages - 1) * 30, (pages - 1) * 30 + 30)
													.map((item) => (
														<>
															<tr>
																<td>{Number(item?.user?.iduser) + 2000}</td>
																<td className="text-left">
																	{item?.user?.username}
																</td>
																<td className="text-left">
																	{item?.id_bet?.id_bet}
																</td>
																<td className="text-left">
																	{Number(item?.moneytai).toFixed(0) > 0
																		? "TH TRUEMILK"
																		: "VINAMILK"}
																</td>
																<td className="text-left">
																	{(
																		item?.moneytai + item?.moneyxiu
																	).toLocaleString()}
																</td>
																<td className="text-left">
																	{item?.status_bet === "Win" ? (
																		<>
																			{(
																				(item?.moneytai + item?.moneyxiu) *
																				2
																			).toLocaleString()}
																		</>
																	) : (
																		"0"
																	)}
																</td>
																<td className="text-left">
																	{formatDate(new Date(item?.createdAt))}
																</td>
																<td className="text-left">
																	<span
																		className={`badge ${
																			item?.status_bet === "Win"
																				? "bg-success"
																				: item?.status_bet === "Lose"
																				? "bg-danger"
																				: "bg-warning"
																		}`}
																	>
																		{item?.status_bet === "Win"
																			? "Thắng"
																			: item?.status_bet === "Lose"
																			? "Thua"
																			: "Chờ xử lý"}
																	</span>
																</td>
																<td className="text-left">
																	{formatDate(new Date(item?.updatedAt))}
																</td>
															</tr>
														</>
													))}
											</tbody>
										) : (
											<div>Đang cập nhật</div>
										)}
									</table>
								</Box>
							</div>
							<Box
								sx={{
									display: "flex",
									justifyContent: "center",
									pt: 3,
								}}
							>
								{data != null ? (
									<Pagination
										color="primary"
										count={Math.floor(data.length / 30) + 1}
										size="small"
										onChange={handleChange}
									/>
								) : null}
							</Box>
						</Container>
					}
				</DashboardLayout>
			</ThemeProvider>
			<style>
				@import
				"https://cdnjs.cloudflare.com/ajax/libs/bootstrap/4.5.3/css/bootstrap.min.css";
			</style>
		</>
	);
}
export default HistoryBetAll;
