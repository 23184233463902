import Footer from "../../components/Footer/Footer";
import "./home.css";
import SettingsSuggestOutlinedIcon from "@mui/icons-material/SettingsSuggestOutlined";

import "./profile.css";

import avt from "../../components/male.jpg";
import { useEffect, useState } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import swal from "sweetalert";
function WithDraw() {
	const [profile, setProfile] = useState(null);
	const [bank, setBank] = useState(null);
	axios.interceptors.request.use(
		(config) => {
			const token = localStorage.getItem("user");

			if (token) {
				config.headers["Authorization"] = `Bearer ${token}`;
			}

			return config;
		},

		(error) => {
			return Promise.reject(error);
		}
	);
	const {
		register,
		handleSubmit,
		setError,
		formState: { errors },
	} = useForm();
	const navigate = useNavigate();
	useEffect(() => {
		axios
			.get(`https://server.vpmilks.com/auth/getUser`, {})
			.then((res) => {
				setProfile(res.data.data);
			})
			.catch((err) => localStorage.removeItem("user"));
		axios
			.get(`https://server.vpmilks.com/bank/getBank`, {})
			.then((res) => {
				setBank(res.data.data);
			})
			.catch((err) => setBank(null));
	}, []);
	const onSubmit = (data) => {
		if (data.money > profile.money) {
			setError("money", {
				type: "minLength",
				message: "Số dư không đủ",
			});
			return;
		}
		const formData = {
			money: Number(data.money),
			type_payment: "RÚT",
			//detail: data.stk + " - " + data.bank + " - " + data.name,
			detail:
				bank[0]?.name_bank + " - " + bank[0]?.fullname + " - " + bank[0]?.stk,
			status_payment: "Pending",
			user: profile._id,
		};
		axios
			.post(`https://server.vpmilks.com/payment/withDraw`, formData)
			.then((res) => {
				swal({
					title: "Thông báo",
					text: "Tạo yêu cầu rút thành công!",
					icon: "success",
					buttons: "OK",
				}).then(() => navigate("/mine"));
			})
			.catch((err) =>
				setError("money", {
					type: "minLength",
					message: err.response.data.message != "internal server error" ? err.response.data.message : "Đã có lỗi xảy ra",
				})
			);
	};
	return (
		<>
			<div className="app1">
				<div className="header_profile">
					<div className="info_profile">
						<div></div>
						<div className="avatar_profile">
							<div className="name_profile"></div>

							<div
								style={{
									marginLeft: "20px",
									fontSize: "1.2rem",
									fontWeight: 600,
									color: "white",
								}}
							>
								Rút tiền
							</div>
						</div>
						<Link to="/mine" className="icon_setting_header">
							&times;
						</Link>
					</div>
				</div>
				<div className="content_profile">
					{/*<img
						style={{ width: "40%", marginTop: "20px" }}
						src="https://play-lh.googleusercontent.com/ufwUy4SGVTqCs8fcp6Ajxfpae0bNImN1Rq2cXUjWI7jlmNMCsXgQE5C3yUEzBu5Gadkz"
						alt=""
					/>
					<div style={{color:"red", fontWeight:600}}>Người nhận: usertest2</div>
								<div style={{color:"red",fontWeight:600}}>Vietcombank 1231231231 </div>*/}
					<form
						style={{ display: "block", padding: "15px", textAlign: "left" }}
						onSubmit={handleSubmit(onSubmit)}
					>
						<div>
							{bank == null ? (
								<>
									{/*<h3 style={{textAlign:"center"}}>Vui lòng thêm ngân hàng</h3>
								<Link to="/addbank" className="btn-submit" style={{textDecoration:"none",textAlign:"center",width:"180px"}}>Thêm ngân hàng</Link>*/}
									<p style={{ textAlign: "center" }}>Đang tải ...</p>
								</>
							) : (
								<>
									<div>
										<input
											className="ipadd"
											type="number"
											{...register("money", { required: true })}
											placeholder="Nhập số tiền cần rút"
										/>
									</div>
									{/*<input
									className="ipadd"
									type="text"
									{...register("stk", { required: true })}
									placeholder="Nhập số tài khoản"
								/>
								<input
									className="ipadd"
									type="text"
									{...register("bank", { required: true })}
									placeholder="Nhập tên ngân hàng"
								/>
								<input
									className="ipadd"
									type="text"
									{...register("name", { required: true })}
									placeholder="Nhập tên chủ tài khoản"
								/>
								<select style={{color: "#333"}}
									{...register("detail", { required: true })}
								>
									<option value="">Chọn ngân hàng</option>
									{bank.map((item, index) => (
										<option
											key={index}
											value={item.name_bank + ' - ' + item.fullname + ' - ' + item.stk}
										>
											{"STK " + item.stk + " - " + item.fullname + " - " + item.name_bank}
										</option>
									))}
									</select>*/}
									<button type="submit" className="btn-submit">
										Xác nhận
									</button>
								</>
							)}

							{errors.money ? (
								<p style={{ color: "red" }}>{errors.money.message}</p>
							) : null}
						</div>
					</form>
				</div>

				<Footer profile={profile} />
			</div>
		</>
	);
}
export default WithDraw;
