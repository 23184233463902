import { DashboardLayout } from "../../components/dashboard-layout";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "../../theme";
import "./add.css";

import {
	Box,
	button,
	Container,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	TextField,
	Pagination,
} from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
function Add() {
	function formatDate(m) {
		new Date(m);
		const dateString =
			m.getUTCFullYear() +
			"/" +
			("0" + (m.getMonth() + 1)).slice(-2) +
			"/" +
			("0" + m.getDate()).slice(-2) +
			"  " +
			("0" + m.getHours()).slice(-2) +
			":" +
			("0" + m.getMinutes()).slice(-2);
		return dateString;
	}
	const [data, setData] = useState(null);
	const [load, setLoad] = useState(false);
	const [newData, setNewData] = useState(null);
	axios.interceptors.request.use(
		(config) => {
			const token = localStorage.getItem("user");

			if (token) {
				config.headers["Authorization"] = `Bearer ${token}`;
			}

			return config;
		},

		(error) => {
			return Promise.reject(error);
		}
	);
	useEffect(() => {
		if (load === false) {
			axios
				.get(`https://server.vpmilks.com/payment/nap`, {})
				.then((res) => {
					setData(res.data.data);
					setNewData(res.data.data);
					localStorage.setItem("data", JSON.stringify(res.data.data));
					setLoad(true);
				});
		}
	}, [load]);
	const requestSearch = (searchedVal) => {
		setSearched(searchedVal);

		if (searchedVal !== "") {
			const filteredRows = JSON.parse(localStorage.getItem("data")).filter(
				(row) => {
					const a = row?.user?.username
						?.toString()
						.toLowerCase()
						.includes(searchedVal?.toString().toLowerCase());
					const b = (Number(row?.user?.iduser) + 2000)
						?.toString()
						.includes(searchedVal?.toString().toLowerCase());
					const c = row?.detail
						?.toString()
						.includes(searchedVal?.toString().toLowerCase());
					return a || b || c;
				}
			);
			setData(filteredRows);
		} else {
			setData(JSON.parse(localStorage.getItem("data")));
		}
	};
	const [pages, setPage] = useState(1);
	const [searched, setSearched] = useState("");
	const handleChange = (e, page) => {
		setPage(page);
	};

	return (
		<>
			<ThemeProvider theme={theme}>
				<DashboardLayout>
					{
						<Container maxWidth={false}>
							<div className="container_set">Yêu cầu nạp tiền</div>
							<div className="form_set">
								<Box sx={{ minWidth: 1200 }} style={{ width: "100%" }}>
									<div className="filter-box">
										<input
											value={searched}
											className="form-control"
											type="text"
											onChange={(searchVal) =>
												requestSearch(searchVal.target.value)
											}
											placeholder="Tìm kiếm"
										/>
										<select
											onChange={(e) => {
												if (e.target.value == "Success") {
													setData(
														newData.filter(
															(item) => item.status_payment === "Success"
														)
													);
												} else if (e.target.value == "Deny") {
													setData(
														newData.filter(
															(item) => item.status_payment === "Deny"
														)
													);
												} else if (e.target.value == "Pending") {
													setData(
														newData.filter(
															(item) => item.status_payment === "Pending"
														)
													);
												} else {
													setData(newData);
												}
											}}
										>
											<option>Trạng thái</option>
											<option value="Success">Đã chuyển khoản</option>
											<option value="Deny">Chưa chuyển khoản</option>
											<option value="Pending">Chờ xử lý</option>
										</select>
									</div>
									<table className="table table-striped table-hover table-sm align-middle">
										<thead>
											<tr>
												<th style={{ width: "5%" }}>ID</th>
												<th className="text-left" style={{ width: "15%" }}>
													Người chơi
												</th>
												<th className="text-left" style={{ width: "15%" }}>
													Thông tin thanh toán
												</th>
												<th className="text-left" style={{ width: "15%" }}>
													Số tiền
												</th>
												<th className="text-left" style={{ width: "15%" }}>
													Ngày tạo
												</th>
												<th className="text-left" style={{ width: "10%" }}>
													Trạng thái
												</th>
												<th className="text-left" style={{ width: "15%" }}>
													Thời gian xử lý
												</th>
												<th style={{ width: "10%" }}>Tác vụ</th>
											</tr>
										</thead>
										<tbody>
											{data
												?.slice((pages - 1) * 30, (pages - 1) * 30 + 30)
												.map((item) => (
													<>
														<tr>
															<td>{Number(item?.user?.iduser) + 2000}</td>
															<td className="text-left">
																{item?.user?.username}
															</td>
															<td className="text-left">{item.detail}</td>
															<td className="text-left">
																{Number(item.money).toLocaleString()}
															</td>
															<td className="text-left">
																{formatDate(new Date(item.updatedAt))}
															</td>
															<td className="text-left">
																<span
																	className={`badge ${
																		item.status_payment === "Success"
																			? "bg-success"
																			: item.status_payment === "Deny"
																			? "bg-danger"
																			: "bg-warning"
																	}`}
																>
																	{item.status_payment === "Success"
																		? "Đã chuyển khoản"
																		: item.status_payment === "Deny"
																		? "Chưa chuyển khoản"
																		: "Chờ xử lý"}
																</span>
															</td>
															<td className="text-left">
																{formatDate(new Date(item.createdAt))}
															</td>
															<td>
																<button
																	className="btn btn-sm btn-success"
																	onClick={() => {
																		const formData = {
																			id: item._id,
																			status: "Accept",
																		};
																		axios
																			.post(
																				`https://server.vpmilks.com/payment/update`,
																				formData
																			)
																			.then((res) => {
																				setLoad(false);
																			})
																			.catch((err) => setLoad(false));
																	}}
																	disabled={item.status_payment !== "Pending"}
																>
																	<i className="fas fa-check"></i>
																</button>
																<button
																	className="btn btn-sm btn-danger"
																	onClick={() => {
																		const formData = {
																			id: item._id,
																			status: "Deny",
																		};
																		axios
																			.post(
																				`https://server.vpmilks.com/payment/update`,
																				formData
																			)
																			.then((res) => {
																				setLoad(false);
																			})
																			.catch((err) => setLoad(false));
																	}}
																	disabled={item.status_payment !== "Pending"}
																>
																	<i className="fas fa-times"></i>
																</button>
															</td>
														</tr>
													</>
												))}
										</tbody>
									</table>
								</Box>
							</div>
							<Box
								sx={{
									display: "flex",
									justifyContent: "center",
									pt: 3,
								}}
							>
								{data != null ? (
									<Pagination
										color="primary"
										count={Math.floor(data.length / 30) + 1}
										size="small"
										onChange={handleChange}
									/>
								) : null}
							</Box>
						</Container>
					}
				</DashboardLayout>
			</ThemeProvider>
			<style>
				@import
				"https://cdnjs.cloudflare.com/ajax/libs/bootstrap/4.5.3/css/bootstrap.min.css";
			</style>
		</>
	);
}
export default Add;
