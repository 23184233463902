import { DashboardLayout } from "../../components/dashboard-layout";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "../../theme";
import "./users.css";
import axios from "axios";

import {
	Box,
	Button,
	Container,
	Table,
	Pagination,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
function Users() {
	function formatDate(m) {
		new Date(m);
		const dateString =
			m.getUTCFullYear() +
			"/" +
			("0" + (m.getMonth() + 1)).slice(-2) +
			"/" +
			("0" + m.getDate()).slice(-2) +
			"  " +
			("0" + m.getHours()).slice(-2) +
			":" +
			("0" + m.getMinutes()).slice(-2);
		return dateString;
	}
	const [users, setUser] = useState(null);
	const [userNew, setNewUser] = useState(null);
	const [load, setLoad] = useState(false);
	const [searched, setSearched] = useState("");
	const [isShow, setShow] = useState(false);
	const [isShow2, setShow2] = useState(false);
	const [dataPopup, setDataPopup] = useState(null);
	const navigate = useNavigate();
	axios.interceptors.request.use(
		(config) => {
			const token = localStorage.getItem("user");

			if (token) {
				config.headers["Authorization"] = `Bearer ${token}`;
			}

			return config;
		},

		(error) => {
			return Promise.reject(error);
		}
	);

	useEffect(() => {
		if (load == false) {
			axios
				.get(`https://server.vpmilks.com/auth/getall`, {})
				.then((res) => {
					localStorage.setItem("data", JSON.stringify(res.data.data));
					setUser(res.data.data);
					setNewUser(res.data.data);
					setLoad(true);
				})
				.then((res) => setLoad(true));
		}
	}, [load]);
	const requestSearch = (searchedVal) => {
		setSearched(searchedVal);

		if (searchedVal !== "") {
			const filteredRows = JSON.parse(localStorage.getItem("data")).filter(
				(row) => {
					const a = row?.username
						?.toString()
						.toLowerCase()
						.includes(searchedVal?.toString().toLowerCase());
					const b = (Number(row.iduser) + 2000)
						?.toString()
						.includes(searchedVal?.toString().toLowerCase());
					return a || b;
				}
			);
			setUser(filteredRows);
		} else {
			setUser(JSON.parse(localStorage.getItem("data")));
		}
	};
	const [pages, setPage] = useState(1);

	const handleChange = (e, page) => {
		setPage(page);
	};
	if (users !== null) {
		return (
			<>
				<ThemeProvider theme={theme}>
					<DashboardLayout>
						{
							<Container maxWidth={false}>
								<div className="container_set">Danh sách thành viên</div>
								<div className="form_set">
									<Box sx={{ minWidth: 1200 }} style={{ width: "100%" }}>
										<div className="filter-box">
											<input
												value={searched}
												className="form-control"
												type="text"
												onChange={(searchVal) =>
													requestSearch(searchVal.target.value)
												}
												placeholder="Tìm kiếm"
											/>
											<select
												onChange={(e) => {
													if (e.target.value == "isLock") {
														setUser(
															userNew.filter((item) => item.isLock === true)
														);
													} else if (e.target.value == "notLock") {
														setUser(
															userNew.filter((item) => item.isLock === false)
														);
													} else {
														setUser(userNew);
													}
												}}
											>
												<option>Trạng thái</option>
												<option value="isLock">Bị khóa</option>
												<option value="notLock">Hoạt động</option>
											</select>
										</div>
										<table className="table table-striped table-hover table-sm align-middle">
											<thead>
												<tr>
													<th style={{ width: "5%" }}>ID</th>
													<th className="text-left" style={{ width: "12%" }}>
														Người chơi
													</th>
													<th className="text-left" style={{ width: "7%" }}>
														Số tiền
													</th>
													<th className="text-left" style={{ width: "7%" }}>
														Tổng cược
													</th>
													<th className="text-left" style={{ width: "7%" }}>
														Tổng nạp
													</th>
													<th className="text-left" style={{ width: "7%" }}>
														Tổng rút
													</th>
													<th className="text-left" style={{ width: "20%" }}>
														Thông tin thêm
													</th>
													<th className="text-left" style={{ width: "10%" }}>
														Ngày tạo
													</th>
													<th className="text-center" style={{ width: "10%" }}>
														Trạng thái
													</th>
													<th style={{ width: "20%" }}>Tác vụ</th>
												</tr>
											</thead>
											<tbody>
												{users != null ? (
													<>
														{users
															?.slice((pages - 1) * 30, (pages - 1) * 30 + 30)
															.map((item) => (
																<tr>
																	<td>{Number(item?.iduser) + 2000}</td>
																	<td className="text-left">
																		{item?.username}
																	</td>
																	<td className="text-left">
																		{item?.money?.toLocaleString()}
																	</td>
																	<td className="text-left">
																		{item?.totalbet?.toLocaleString()}
																	</td>
																	<td className="text-left">
																		{item?.tongnap?.toLocaleString()}
																	</td>
																	<td className="text-left">
																		{item?.tongrut?.toLocaleString()}
																	</td>
																	<td className="text-left">
																		Tổng admin cộng:{" "}
																		<span>
																			{item?.adminadd?.toLocaleString()}
																		</span>
																		<br />
																		Tổng admin thưởng:{" "}
																		<span>
																			{item?.adminthuong?.toLocaleString()}
																		</span>
																		<br />
																		Tổng admin trừ:{" "}
																		<span>
																			{item?.admintru?.toLocaleString()}
																		</span>
																	</td>
																	<td className="text-left">
																		{formatDate(new Date(item?.createdAt))}
																	</td>
																	<td>
																		{item?.isLock == false ? (
																			<span className="badge bg-success">
																				Hoạt động
																			</span>
																		) : (
																			<span className="badge bg-danger">
																				Bị khóa
																			</span>
																		)}
																	</td>
																	<td>
																		<button
																			className="btn btn-sm btn-info"
																			onClick={() => {
																				setDataPopup(item);
																				setShow(true);
																			}}
																		>
																			+/-
																		</button>
																		<button
																			className="btn btn-sm btn-dark"
																			style={{
																				background: "orange",
																				borderColor: "orange",
																			}}
																			onClick={() => {
																				setDataPopup(item);
																				setShow2(true);
																			}}
																		>
																			<i className="fa fa-gift" />
																		</button>
																		{item?.isLock == false ? (
																			<button
																				className="btn btn-sm btn-danger"
																				onClick={() => {
																					axios
																						.post(
																							`https://server.vpmilks.com/auth/lockkey`,
																							{
																								id: item?._id,
																								isLock: true,
																							}
																						)
																						.then((res) => {
																							setLoad(false);
																							setSearched("");
																							window.location.reload();
																						});
																				}}
																			>
																				<i className="fa fa-key" />
																			</button>
																		) : (
																			<button
																				className="btn btn-sm btn-warning"
																				onClick={() => {
																					axios
																						.post(
																							`https://server.vpmilks.com/auth/lockkey`,
																							{
																								id: item?._id,
																								isLock: false,
																							}
																						)
																						.then((res) => {
																							setLoad(false);
																							setSearched("");
																							window.location.reload();
																						});
																				}}
																			>
																				<i className="fa fa-lock-open" />
																			</button>
																		)}
																		<button
																			className="btn btn-sm btn-primary"
																			onClick={() =>
																				navigate(`/admin/user/${item?._id}`)
																			}
																		>
																			<i className="fa fa-pen" />
																		</button>
																	</td>
																</tr>
															))}
													</>
												) : null}
											</tbody>
										</table>
										<Box
											sx={{
												display: "flex",
												justifyContent: "center",
												pt: 3,
											}}
										>
											{users != null ? (
												<Pagination
													color="primary"
													count={Math.floor(users.length / 30) + 1}
													size="small"
													onChange={handleChange}
												/>
											) : null}
										</Box>
									</Box>
								</div>
							</Container>
						}
					</DashboardLayout>
					{isShow === true ? (
						<>
							<div className="modal">
								<div className="modaloverlay">
									<i className="ti-close closelogin"></i>
								</div>
								<div className="modalbody">
									<form
										onSubmit={(e) => {
											e.preventDefault();
											const dataForm = {
												id: dataPopup?._id,
												money: e.target.money.value,
											};
											axios
												.post(
													`https://server.vpmilks.com/auth/update`,
													dataForm
												)
												.then((res) => {
													setUser(res.data.data);
													setLoad(false);
													setSearched("");
													setShow(false);
													swal("Thành công", "", "success");
												});
										}}
									>
										<div className="modalinner">
											<div className="modalheader"> Cộng / trừ tiền </div>

											<div className="modalform">
												<input
													style={{ width: "250px", margin: "0 0 20px" }}
													id={dataPopup?._id}
													name="money"
													type="number"
													placeholder="Nhập số tiền"
												/>
											</div>

											<div className="item_btn_form">
												<div className="modalformcontrols">
													<Button type="submit">XÁC NHẬN</Button>
												</div>
												<div className="modalformcontrols">
													<Button onClick={() => setShow(false)}>ĐÓNG</Button>
												</div>
											</div>
										</div>
									</form>
								</div>
							</div>
						</>
					) : null}
					{isShow2 === true ? (
						<>
							<div className="modal">
								<div className="modaloverlay">
									<i className="ti-close closelogin"></i>
								</div>
								<div className="modalbody">
									<form
										onSubmit={(e) => {
											e.preventDefault();
											const dataForm = {
												id: dataPopup?._id,
												money: e.target.money.value,
												msg: e.target.msg.value,
											};
											axios
												.post(
													`https://server.vpmilks.com/auth/adminthuong`,
													dataForm
												)
												.then((res) => {
													setLoad(false);
													setSearched("");
													setShow2(false);
													swal("Thành công", "Thưởng thành công!", "success");
												});
										}}
									>
										<div className="modalinner">
											<div className="modalheader">
												Thưởng quà &nbsp;<b> {dataPopup.username}</b>
											</div>

											<div className="modalform">
												<input
													style={{ width: "250px", margin: "0 0 20px" }}
													id={dataPopup?._id}
													name="money"
													type="number"
													placeholder="Nhập số tiền"
												/>
												<input
													style={{ width: "250px", margin: "0 0 20px" }}
													id={dataPopup?._id}
													name="msg"
													type="text"
													placeholder="Nhập nội dung"
												/>
											</div>

											<div className="item_btn_form">
												<div className="modalformcontrols">
													<Button type="submit">XÁC NHẬN</Button>
												</div>
												<div className="modalformcontrols">
													<Button onClick={() => setShow2(false)}>ĐÓNG</Button>
												</div>
											</div>
										</div>
									</form>
								</div>
							</div>
						</>
					) : null}
				</ThemeProvider>
				<style>
					@import
					"https://cdnjs.cloudflare.com/ajax/libs/bootstrap/4.5.3/css/bootstrap.min.css";
				</style>
			</>
		);
	} else {
		return (
			<>
				<div>Loading...</div>
			</>
		);
	}
}
export default Users;
