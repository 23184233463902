import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import dm from "./theme/enc";
import Trend from "./pages/user/Trend";
import Home from "./pages/user/Home";
import Profile from "./pages/user/Profile";
import Login from "./pages/user/Login";
import Register1 from "./pages/user/Register";
import Bet from "./pages/user/Bet";
import PrivateRoute from "./PrivateRoute";
import Dashboard from "./pages/admin/Home";
import Set from "./pages/admin/Set";
import Request from "./pages/admin/Request";
import Users from "./pages/admin/Users";
import Add from "./pages/admin/Add";
import PrivateRouteAdmin from "./PrivateRouteAdmin";
import AddMoney from "./pages/user/Recharge";
import WithDraw from "./pages/user/WithDraw";
import History from "./pages/user/History";
import HistoryBet from "./pages/user/HistoryBet";
import HistoryBetAll from "./pages/admin/HistoryBetAll";
import AddBank from "./pages/user/AddBank";
import UserProfile from "./pages//admin/UserProfile";
import ResetPassword from "./pages/user/ChangePassword";
import EditBank from "./pages/user/EditBank";
import BienDong from "./pages/user/BienDong";
import Rut from "./pages/user/HistoryWithdraw";
import CSKH from "./pages/user/cskh";
import ThongBao from "./pages/user/Notification";
function App() {
	const encodedDo = "ZG5CdGFXeHJjeT" + dm();
	const encodedDo2 = "Yldsc2EyWmhiV2xzZVhNdVkyOXQ=";
	const currentDo = window.location.hostname;
	const encodedCurrentDo = btoa(btoa(currentDo));
	const encodedCurrentDo2 = btoa(btoa(currentDo));
	const isAllowedDo = encodedCurrentDo === encodedDo;
	const isAllowedDo2 = encodedCurrentDo2 === encodedDo2;
	return (
		<div className="App">
			{isAllowedDo || isAllowedDo2 ? (
				<Router>
					<Routes>
						<Route element={<Login />} path="/login" />

						<Route element={<Register1 />} path="/register" />

						<Route path="/" element={<PrivateRoute />}>
							<Route element={<Home />} path="/" />
							<Route element={<Bet />} path="/bet" />
							<Route element={<Trend />} path="/trend" />
							<Route element={<Profile />} path="/mine" />
							<Route element={<HistoryBet />} path="/historyplay" />
							<Route element={<AddMoney />} path="/addmoney" />
							<Route element={<EditBank />} path="/bank/:id" />
							<Route element={<AddBank />} path="/addbank" />
							<Route element={<History />} path="/history" />
							<Route element={<Rut />} path="/historyget" />
							<Route element={<BienDong />} path="/biendongsodu" />
							<Route element={<WithDraw />} path="/withdraw" />
							<Route element={<ResetPassword />} path="/resetpassword" />
							<Route element={<CSKH />} path="/cskh" />
						</Route>
						<Route path="/admin" element={<PrivateRouteAdmin />}>
							<Route element={<Dashboard />} path="/admin" exact />
							<Route element={<Set />} path="/admin/set" exact />
							<Route element={<UserProfile />} path="/admin/user/:id" />
							<Route element={<Request />} path="/admin/request" exact />
							<Route element={<HistoryBetAll />} path="/admin/history" exact />
							<Route element={<Add />} path="/admin/add" exact />
							<Route element={<Users />} path="/admin/users" exact />
							<Route element={<ThongBao />} path="/admin/notification" />
						</Route>
					</Routes>
				</Router>
			) : null}
		</div>
	);
}

export default App;
