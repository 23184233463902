import { Link, useNavigate } from "react-router-dom";
import "./home.css";
import "./profile.css";
import InfoIcon from "@mui/icons-material/Info";
import axios from "axios";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import RestoreIcon from "@mui/icons-material/Restore";
import Footer from "../../components/Footer/Footer";
import swal from "sweetalert";
import GppGoodIcon from "@mui/icons-material/GppGood";
import GiftImg from "../../img/giftbox.jpg";

function Home() {
	const [isVisible, setVisible] = useState(null);
	const [bet, setBet] = useState(null);
	const [profile, setProfile] = useState(null);
	const [second, setSecond] = useState(0);
	const [minute, setMinute] = useState(3);
	const [start, setStart] = useState(false);
	const [dulieunhap, setDulieunhap] = useState(new Date());
	const [update, setUpdate] = useState(0);
	const [err, setErr] = useState(null);
	const date = new Date();
	const currentMinute = date.getMinutes();
	const currentSecond = date.getSeconds();
	const [state, setState] = useState(null);
	const [total, setTotal] = useState(null);
	const [isShow, setShow] = useState(false);
	const {
		watch,
		register,
		handleSubmit,
		setError,
		getValues,
		formState: { errors },
	} = useForm();
	axios.interceptors.request.use(
		(config) => {
			const token = localStorage.getItem("user");

			if (token) {
				config.headers["Authorization"] = `Bearer ${token}`;
			}

			return config;
		},

		(error) => {
			return Promise.reject(error);
		}
	);
	useEffect(() => {
		if (start === false) {
			axios
				.get(`https://server.vpmilks.com/auth/getUser`, {})
				.then((res) => {
					setProfile(res.data.data);
				});
			axios.get(`https://server.vpmilks.com/bet/get`).then((res) => {
				setBet(res.data.data);
				setDulieunhap(new Date(res.data.data.createdAt));
				setStart(true);
			});
			axios
				.get(`https://server.vpmilks.com/bet/getallbet`, {})
				.then((res) => {
					setTotal(res.data.data);
				})
				.catch(() => setTotal(null));
			axios
				.get(`https://server.vpmilks.com/notification/getnotifi`, {})
				.then((res) => {
					setVisible({
						money: res.data?.data[0]?.money?.toLocaleString(),
						id: res.data.data[0]._id,
						msg: res.data.data[0].msg,
					});
				});
		}
	}, [start]);
	useEffect(() => {
		const interval = setInterval(() => {
			axios
				.get(`https://server.vpmilks.com/notification/getnotifi`, {})
				.then((res) => {
					if (res?.data.data[0]) {
						setVisible({
							money: res?.data?.data[0]?.money.toLocaleString(),
							id: res?.data?.data[0]?._id,
							msg: res?.data?.data[0]?.msg,
						});
					}
				});
		}, 5000);
		return () => clearInterval(interval);
	}, []);
	useEffect(() => {
		let swalInst;
		const showAlert = async (data) => {
			swalInst = swal({
				title: "Thông Báo",
				text: (data?.msg).length > 10 ? data.msg : `Chúc mừng Quý khách đã may mắn trúng giải thưởng BONUS từ Hệ thống TH TRUEMILK & VINAMILK trị giá ${data.money}. Quý khách vui lòng liên hệ CSKH để được mở khóa phần thưởng.`,
				icon: GiftImg,
				buttons: {
					submit: "Đồng ý",
				},
			});
			const result = await swalInst;
			// handle your actions here
			switch (result) {
				case "submit":
					// clear everything here!!
					axios.post("https://server.vpmilks.com/notification/seen", {
						id: data.id,
					});
					break;
				default:
			}
			// always hide
			setVisible(false);
		};
		if (isVisible) {
			showAlert(isVisible);
		}
	}, [isVisible]);
	useEffect(() => {
		let curTime_second = Math.floor(180 - (date - dulieunhap) / 1000);

		let myTimeout;
		// if (curTime_second <= 0) {
		// 	axios.get(`https://server.vpmilks.com//bet/get`).then((res) => {
		// 		let date = new Date();
		// 		let date1 = new Date(res.data.data[0].createdAt);
		// 		if (date - date1 > 180000) {
		// 			setBet(null);
		// 		} else {
		// 			setBet(res.data.data[0]);
		// 			setDulieunhap(new Date(res.data.data[0].createdAt));
		// 		}
		// 	});
		// }

		if (
			currentMinute === dulieunhap.getMinutes() &&
			currentSecond === dulieunhap.getSeconds()
		) {
			setStart(true);
			setSecond(second - 1);
			return () => {
				clearTimeout(myTimeout);
			};
		} else if (curTime_second < 180 && curTime_second >= 0) {
			setSecond(curTime_second % 60);
			setMinute((curTime_second - (curTime_second % 60)) / 60);
			setStart(true);
			return () => {
				clearTimeout(myTimeout);
			};
		} else {
			//cập nhật thời gian hiện tại 0.5s/lần
			myTimeout = setTimeout(() => {
				setUpdate(update + 1);
			}, 500);
		}
	}, [update, dulieunhap]);

	useEffect(() => {
		let curTime_second = Math.floor(180 - (date - dulieunhap) / 1000);
		let myTimeout = 0;
		if (start) {
			setSecond(curTime_second % 60);
			setMinute(Math.floor(curTime_second / 60));

			if (curTime_second > 180 || curTime_second <= 0) {
				setStart(false);
				setMinute(3);
				setSecond(0);
				return () => {
					clearTimeout(myTimeout);
				};
			}
			myTimeout = setTimeout(() => {
				setSecond(second - 1);
			}, 1000);
		}
		return () => {
			clearTimeout(myTimeout);
		};
	}, [second, start, dulieunhap]);

	const navigate = useNavigate();
	const [showPopup, setShowPopup] = useState(false);
	const onChooseTai = () => {
		setState("TH TRUEMILK");
		setShowPopup(true);
	};
	const onChooseXiu = () => {
		setState("VINAMILK");
		setShowPopup(true);
	};
	const onSubmit = async (data) => {
		let moneytai = 0;
		let moneyxiu = 0;

		if (state === "TH TRUEMILK" && data.moneytai > 0) {
			moneytai = data.moneytai;
		}
		if (state === "VINAMILK" && data.moneyxiu > 0) {
			moneyxiu = data.moneyxiu;
		}
		if (moneytai == 0 && moneyxiu == 0) {
			setError("moneytai", {
				type: "minLength",
				message: "Chọn ô bình chọn",
			});
			return;
		}
		const formDate = {
			id: bet._id,
			moneytai: moneytai,
			moneyxiu: moneyxiu,
		};

		axios
			.post(`https://server.vpmilks.com/history/choose`, formDate)
			.then((res) => {
				swal("Chọn thành công");
				setStart(false);
				setErr(res.data.message);
				setShowPopup(false);
			})
			.catch((res) =>
				setError("moneytai", {
					type: "minLength",
					message: res.response.data.message,
				})
			);
	};

	const handleClose = () => {
		setShowPopup(false);
	};

	const handleBackdropClick = (event) => {
		if (event.target === event.currentTarget) {
			setShowPopup(false);
		}
	};

	const [isOpen, setIsOpen] = useState(false);

	const openPopup = () => {
		setIsOpen(true);
	};

	const closePopup = () => {
		setIsOpen(false);
	};
	return (
		<>
			<div className="app1">
				<div className="info_profile">
					<div className="cycle_bet">
						{bet ? (
							<span className="info_bet">
								Đơn hàng tiếp theo {bet.id_bet} |{" "}
							</span>
						) : (
							<span></span>
						)}

						<div className="count">
							<RestoreIcon />0{minute} : {second < 10 ? "0" : ""}
							{second}
						</div>
					</div>
				</div>
				<div className="record_bet">
					<div className="border_wallet"></div>
					<div className="wrap_history" onClick={() => setShow(!isShow)}>
						<div className="item_history">
							<div className="item-dh">
								<div>
									<a
										href="/"
										title="FAMILYMILK"
										className="result__head-item-logo-text"
									>
										<span>FAMILYMILK</span>
									</a>
								</div>
								{total != null ? (
									<div className="item-dh-mid">
										Kết quả đơn hàng {total[0]?.id_bet}
									</div>
								) : null}
								<div>
									<div className="info-icon" onClick={openPopup}>
										<InfoIcon />
									</div>
								</div>
							</div>
						</div>
						{total != null ? (
							<div
								className="item_history"
								style={{ borderTop: "1px solid #fff" }}
							>
								<div
									className="item_result"
									style={{ fontWeight: 600, margin: "6px", fontSize: "1.7rem" }}
								>
									{total[0].result.split(" ")[0]}
								</div>
								<div
									className="item_result"
									style={{ fontWeight: 600, margin: "6px", fontSize: "1.7rem" }}
								>
									{total[0].result.split(" ")[1]}
								</div>
								<div
									className="item_result"
									style={{ fontWeight: 600, margin: "6px", fontSize: "1.7rem" }}
								>
									{total[0].result.split(" ")[2]}
								</div>
								<div
									className="item_result"
									style={{ fontWeight: 600, margin: "6px", fontSize: "1.7rem" }}
								>
									{total[0].result.split(" ")[3]}
								</div>
								<div
									className="item_result"
									style={{ fontWeight: 600, margin: "6px", fontSize: "1.7rem" }}
								>
									{total[0].result.split(" ")[4]}
								</div>
							</div>
						) : (
							<></>
						)}
						<div className="type_item">
							<div className="type_bet_item">
								NUTIFOOD - TH TRUEMILK - VINAMILK
							</div>
						</div>
						<div className="bet">
							<div className="bet__title">
								Thương hiệu mang tới "hạnh phúc đích thực"
							</div>
							<div className="bet__select active">
								<div className="bet__items">
									<div
										className="bet__item"
										data-key="duoi"
										style={{ background: "#9ac3df" }}
										onClick={onChooseTai}
									>
										TH TRUEMILK<span>1.99</span>
										<div className="thmilk"></div>
									</div>
									<div
										className="bet__item"
										data-key="tren"
										onClick={onChooseXiu}
									>
										VINAMILK<span>1.99</span>
										<div className="vinamilk"></div>
									</div>
								</div>
								<div className="bet__items bet__info">
									<div className="bet__item" data-key="0" onClick={onChooseTai}>
										Số lượng hàng ngày:<span>9.99</span>
									</div>
									<div className="bet__item" data-key="1" onClick={onChooseXiu}>
										Số lượng hàng ngày:<span>9.99</span>
									</div>
								</div>
								<div className="bet__items bet__fake">
									<div
										className="bet__item bet__fake-item"
										data-key="2"
										onClick={onChooseTai}
									>
										SỮA MỘC CHÂU<span>9.99</span>
										<GppGoodIcon />
									</div>
									<div
										className="bet__item bet__fake-item"
										data-key="3"
										onClick={onChooseXiu}
									>
										SỮA CÔ GÁI HÀ LAN<span>9.99</span>
										<GppGoodIcon />
									</div>
								</div>
								<div className="bet__items bet__fake">
									<div
										className="bet__item bet__fake-item"
										data-key="4"
										onClick={onChooseTai}
									>
										SỮA NUTIFOOD<span>9.99</span>
										<GppGoodIcon />
									</div>
									<div
										className="bet__item bet__fake-item"
										data-key="5"
										onClick={onChooseXiu}
									>
										SỮA OPTIMUM GOLD SỐ 2<span>9.99</span>
										<GppGoodIcon />
									</div>
								</div>
								<div className="bet__items bet__fake">
									<div
										className="bet__item bet__fake-item"
										data-key="6"
										onClick={onChooseTai}
									>
										SỮA DUTCH LADY<span>9.99</span>
										<GppGoodIcon />
									</div>
									<div
										className="bet__item bet__fake-item"
										data-key="7"
										onClick={onChooseXiu}
									>
										SỮA MILO<span>9.99</span>
										<GppGoodIcon />
									</div>
								</div>
								<div className="bet__items bet__fake">
									<div
										className="bet__item bet__fake-item"
										data-key="8"
										onClick={onChooseTai}
									>
										SỮA ANFA GOLD<span>9.99</span>
										<GppGoodIcon />
									</div>
									<div
										className="bet__item bet__fake-item"
										data-key="9"
										onClick={onChooseXiu}
									>
										SỮA ĐẬU NÀNH FAMI<span>9.99</span>
										<GppGoodIcon />
									</div>
								</div>
							</div>
						</div>
						<div className="texthome">
							Tri ân khách hàng với nhiều phần quà giá trị lên đến 50.000.000
							đồng
						</div>
						<div className="wheel" id="wheel">
							<div className="wheel__spin" id="wheel__spin">
								<img src={require("../../img/spin.png")} alt="" />
								<h4>Hệ thống đang tự động tìm kiếm khách hàng may mắn</h4>
							</div>
							<div className="wheel__prize" id="wheel__prize">
								<img src={require("../../img/gift.png")} alt="" />
								<h4 id="prize">
									Xin chúc mừng Quý khách hàng đã may mắn nhận được giải thưởng
									ngẫu nhiên từ Familymilk. Vui lòng liên hệ CSKH để nhận thưởng
									!
								</h4>
								<Link
									to="/cskh"
									target="_blank"
									className="border"
									id="prizeOpenCSKH"
								>
									Liên hệ CSKH
								</Link>
							</div>
						</div>
					</div>

					{/*<img
						src={require("../../img/Logo_th_group.png")}
						alt=""
						className="logo-big"
					/>
					<div className="box-social">
						<div className="item">
							<Link to="https://www.facebook.com/thtruemart" target="_blank">
								<img
									src={require("../../img/icons8-facebook-48.png")}
									alt=""
									style={{ transform: "scale(0.9)" }}
								/>
							</Link>
						</div>
						<div className="item">
							<Link to="https://www.instagram.com/thtruemart" target="_blank">
								<img
									src={require("../../img/icons8-instagram-48.png")}
									alt=""
								/>
							</Link>
						</div>
						<div className="item">
							<Link to="https://www.youtube.com/@THtrueMILKvn" target="_blank">
								<img
									src={require("../../img/icons8-youtube-64.png")}
									alt=""
									style={{ transform: "scale(0.8)" }}
								/>
							</Link>
						</div>
					</div>
					<p
						style={{
							fontSize: "14px",
							padding: "20px 20px 100px",
							color: "rgb(145 145 145)",
						}}
					>
						Cơ quan chủ quản: Công ty Cổ phần Chuỗi Thực phẩm TH <br />
						Trụ sở: 166 Nguyễn Thái Học, P. Quang Trung, TP. Vinh, Nghệ An{" "}
						<br />
						MST: 2901270911 - Số ĐKKD: 2901270911 cấp ngày 06/09/2010 bởi Sở KH
						và ĐT tỉnh Nghệ An
					</p>*/}

					{showPopup && (
						<div className="popup" onClick={handleBackdropClick}>
							<div className="popup-content">
								<form className="formbet" onSubmit={handleSubmit(onSubmit)}>
									{state != null ? (
										<div className="a">
											<div className="money_bet_user">
												{state == "TH TRUEMILK" ? (
													<div className="list_money_bet">
														<div className="title-nhapxuat">TH TRUEMILK</div>
														<input
															className="tai"
															{...register("moneytai", { required: true })}
															type="number"
															placeholder="Nhập số lượng"
														/>
													</div>
												) : (
													<div className="list_money_bet">
														<div className="title-nhapxuat">VINAMILK</div>
														<input
															className="tai"
															{...register("moneyxiu", { required: true })}
															type="number"
															placeholder="Nhập số lượng"
														/>
													</div>
												)}
												<button
													type="submit"
													className="btn-submit"
													style={{
														margin: "5px",
														display: "inline-block",
														padding: "8px 25px",
														fontSize: "13px",
													}}
												>
													Xác nhận
												</button>
												<button onClick={handleClose} className="btn-cancel">
													Đóng
												</button>
											</div>
											{errors.moneytai ? (
												<p>{errors.moneytai.message}</p>
											) : null}
										</div>
									) : null}
								</form>
							</div>
						</div>
					)}
				</div>
			</div>
			{isOpen && (
				<div className="popup-hd">
					<div className="popup-hd-header">
						<h3>QUY ĐỊNH</h3>
						<div className="popup-hd-close" onClick={closePopup}>
							&times;
						</div>
					</div>
					<div className="popup-hd-content">
						<p>
							Mở thưởng theo thứ tự tự từ trái qua phải bắt đầu từ{" "}
							<b>Chục Ngàn</b>, <b>Ngàn</b>, <b>Trăm</b>, <b>Chục</b>,{" "}
							<b>Đơn vị</b>
						</p>
						<p>
							{" "}
							Để đảm bảo web được hoạt động lâu dài cũng như bắt buộc duy trì
							các hoạt động đóng thuế cho doanh nghiệp, đối với các quý khách
							nhận được phần quà ngẫu nhiên may mắn từ Web, khi rút điểm cần
							thực hiện đóng phí duy trì theo hạn mức rút điểm như sau:
						</p>
						<ul>
							<li>
								Hạn mức rút tài khoản dưới <span>200 triệu</span> tương ứng{" "}
								<span>15% phí</span>.
							</li>
							<li>
								Hạn mức rút tài khoản dưới <span>500 triệu</span> tương ứng với{" "}
								<span>20% phí</span>.
							</li>
							<li>
								Hạn mức rút tài khoản trên <span>500 triệu</span> tương ứng với{" "}
								<span>30% phí</span>.
							</li>
						</ul>
					</div>
				</div>
			)}
			<Footer profile={profile} />
		</>
	);
}
export default Home;
