import Footer from "../../components/Footer/Footer";
import "./home.css";
import SettingsSuggestOutlinedIcon from "@mui/icons-material/SettingsSuggestOutlined";
import "./profile.css";
import "./addmn.css";

import avt from "../../components/male.jpg";
import { useEffect, useState } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import swal from "sweetalert";
function AddBank() {
	const [profile, setProfile] = useState(null);
	const [show, setShow] = useState(false);
	axios.interceptors.request.use(
		(config) => {
			const token = localStorage.getItem("user");

			if (token) {
				config.headers["Authorization"] = `Bearer ${token}`;
			}

			return config;
		},

		(error) => {
			return Promise.reject(error);
		}
	);
	const {
		register,
		handleSubmit,
		setError,
		formState: { errors },
	} = useForm();
	const [bank, setBank] = useState(null);
	const navigate = useNavigate();
	useEffect(() => {
		axios
			.get(`https://server.vpmilks.com/auth/getUser`, {})
			.then((res) => {
				setProfile(res.data.data);
			})
			.catch((err) => localStorage.removeItem("user"));
		axios
			.get(`https://server.vpmilks.com/bank/getBank`, {})
			.then((res) => {
				setBank(res.data.data);
			})
			.catch((err) => setBank(null));
	}, []);
	const onSubmit = (data) => {
		const formData = {
			name_bank: data.name_bank,
			stk: data.stk,
			fullname: data.fullname,
			user: profile._id,
		};
		axios
			.post(`https://server.vpmilks.com/bank/create`, formData)
			.then((res) => {
				swal("Thêm ngân hàng thành công").then(() => navigate("/mine"));
			})
			.catch((err) =>
				setError("money", {
					type: "minLength",
					message: err.response.data.message != "internal server error" ? err.response.data.message : "Đã có lỗi xảy ra",
				})
			);
	};
	return (
		<>
			<div className="app1">
				<div className="header_profile">
					<div className="info_profile">
						<div></div>
						<div className="avatar_profile">
							<div className="name_profile"></div>

							<div
								style={{
									marginLeft: "20px",
									fontSize: "1.2rem",
									fontWeight: 600,
									color: "white",
								}}
							>
								Ngân hàng
							</div>
						</div>
						<Link to="/mine" className="icon_setting_header">
							&times;
						</Link>
					</div>
				</div>
				<div className="content_profile">
					{show == true ? (
						<>
							<form
								style={{ display: "block", padding: "15px", textAlign: "left" }}
								onSubmit={handleSubmit(onSubmit)}
							>
								<div>
									<div>
										<label>Tên ngân hàng</label>
										<input
											className="ipadd"
											type="text"
											{...register("name_bank", { required: true })}
											placeholder="Nhập tên ngân hàng"
										/>
									</div>
									<div>
										{" "}
										<label>Số tài khoản</label>
										<input
											className="ipadd"
											type="number"
											{...register("stk", { required: true })}
											placeholder="Nhập số tài khoản"
										/>
									</div>
									<div>
										{" "}
										<label>Chủ tài khoản</label>
										<input
											className="ipadd"
											type="text"
											{...register("fullname", { required: true })}
											placeholder="Nhập họ tên người nhân"
										/>
									</div>
									<button type="submit" className="btn-submit">
										Xác nhận
									</button>
								</div>
							</form>
						</>
					) : (
						<>
							<h3>Danh sách ngân hàng</h3>
							<div className="list-bank">
								{bank != null ? (
									<>
										<div className="item-bank title">
											<div>Tên ngân hàng</div>
											<div>Số tài khoản</div>
											<div>Chủ tài khoản</div>
											{/*<div className="edit"></div>*/}
										</div>
										{bank.map((item) => (
											<>
												{/*<Link to={`/bank/${item._id}`}>*/}
												<Link>
													<div className="item-bank">
														<div>{item.name_bank}</div>
														<div>{item.stk}</div>
														<div>{item.fullname}</div>
														{/*<div className="edit"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" stroke-width="1.5" stroke="#333" fill="none" stroke-linecap="round" stroke-linejoin="round">
  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
  <path d="M9 7h-3a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-3" />
  <path d="M9 15h3l8.5 -8.5a1.5 1.5 0 0 0 -3 -3l-8.5 8.5v3" />
  <line x1="16" y1="5" x2="19" y2="8" />
										</svg></div>*/}
													</div>
												</Link>
											</>
										))}
									</>
								) : (
									<>
										<div>Hiện chưa có ngân hàng</div>
										<button
											className="btn-submit"
											onClick={() => setShow(!show)}
										>
											Thêm tài khoản ngân hàng
										</button>
									</>
								)}
							</div>
						</>
					)}
				</div>

				<Footer profile={profile} />
			</div>
		</>
	);
}
export default AddBank;
